var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full my-4"},[_c('ul',{staticClass:"flex flex-wrap list-none space-y-2 md:space-y-3 flex-col ml-4"},[(_vm.employe)?_c('li',{staticClass:"w-full max-w-max flex md:space-x-2 bg-blue-200 rounded-b-md rounded-tr-md p-2 shadow-md"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex space-x-2"},[(_vm.employe.personal.personal_avatar)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                ((_vm.employe.personal.first_name) + " " + (_vm.employe.personal.last_name))
              ),expression:"\n                `${employe.personal.first_name} ${employe.personal.last_name}`\n              "}],staticClass:"rounded-full w-10 h-10 object-cover",attrs:{"src":_vm.employe.personal.personal_avatar}}):_c('avatar',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
  ((_vm.employe.personal.first_name) + " " + (_vm.employe.personal.last_name))
),expression:"\n  `${employe.personal.first_name} ${employe.personal.last_name}`\n"}],attrs:{"size":38,"username":((_vm.employe.personal.first_name) + " " + (_vm.employe.personal.last_name))}}),(_vm.employe.mark || _vm.employe.rating)?_c('span',{staticClass:"font-semibold rounded px-2 py-1 flex bg-blue-600 text-white self-start"},[_vm._v(" "+_vm._s(_vm.marks(_vm.employe.mark) || _vm.employe.rating)+" ")]):_c('span',{staticClass:"border rounded dashed  px-2 py-1 flex bg-gray-200 text-white"},[_c('span',{staticClass:"opacity-0"},[_vm._v("N")])]),(_vm.employe.comment)?_c('div',{staticClass:"comment-component flex flex-col text-sm text-white-800 pl-3 pr-3",staticStyle:{"white-space":"pre-line"},attrs:{"type":"text"},domProps:{"innerHTML":_vm._s(_vm.employe.comment)}}):_vm._e()],1)])]):_vm._e(),(_vm.supervisor)?_c('li',{staticClass:"w-full max-w-max flex md:space-x-2 bg-blue-200 rounded-b-md rounded-tr-md p-2 shadow-md"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex space-x-2"},[(_vm.supervisor.personal.personal_avatar)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                ((_vm.supervisor.personal.first_name) + " " + (_vm.supervisor.personal.last_name))
              ),expression:"\n                `${supervisor.personal.first_name} ${supervisor.personal.last_name}`\n              "}],staticClass:"rounded-full w-10 h-10 object-cover",attrs:{"src":_vm.supervisor.personal.personal_avatar}}):_c('avatar',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
  ((_vm.supervisor.personal.first_name) + " " + (_vm.supervisor.personal.last_name))
),expression:"\n  `${supervisor.personal.first_name} ${supervisor.personal.last_name}`\n"}],attrs:{"size":38,"username":((_vm.supervisor.personal.first_name) + " " + (_vm.supervisor.personal.last_name))}}),(_vm.supervisor.mark || _vm.supervisor.rating)?_c('span',{staticClass:"font-semibold rounded px-2 py-1 flex bg-blue-600 text-white self-start"},[_vm._v(" "+_vm._s(_vm.marks(_vm.supervisor.mark) || _vm.supervisor.rating)+" ")]):_c('span',{staticClass:"rounded dashed px-2 py-1 flex bg-gray-200 text-white"},[_c('span',{staticClass:"opacity-0"},[_vm._v("N")])]),(_vm.supervisor.comment)?_c('div',{staticClass:"comment-component flex flex-col text-sm text-white-800 pl-3 pr-3",staticStyle:{"white-space":"pre-line","list-style":"decimal"},domProps:{"innerHTML":_vm._s(_vm.supervisor.comment)}}):_vm._e()],1)])]):_vm._e(),(_vm.netural && _vm.netural.length)?_c('li',{staticClass:"w-full max-w-max flex md:space-x-2 bg-blue-200 rounded-b-md rounded-tr-md p-2 shadow-md"},[_c('ul',{staticClass:"w-full flex mt-4"},_vm._l((_vm.netural),function(ne,index){return _c('li',{key:("external-" + index)},[(ne.mark || ne.rating)?_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex space-x-2"},[_c('avatar',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(ne.email),expression:"ne.email"}],attrs:{"size":38,"username":ne.email}}),(ne.mark || ne.rating)?_c('span',{staticClass:"font-semibold rounded px-2 py-1 flex bg-blue-600 text-white self-start"},[_vm._v(" "+_vm._s(_vm.marks(ne.mark) || ne.rating)+" ")]):_c('span',{staticClass:"border rounded dashed px-2 py-1 flex bg-gray-200 text-white"},[_c('span',{staticClass:"opacity-0"},[_vm._v("N")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(ne.comment),expression:"ne.comment"}],staticClass:"comment-component  flex flex-col  text-sm text-white-800 pl-3 pr-3",staticStyle:{"white-space":"pre-line","list-style":"decimal"},domProps:{"innerHTML":_vm._s(ne.comment)}})],1)]):_vm._e()])}),0)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }