<template>
  <div class="w-full my-4">
    <ul class="flex flex-wrap list-none space-y-2 md:space-y-3 flex-col ml-4">
      <li v-if="employe" class="w-full max-w-max flex md:space-x-2 bg-blue-200 rounded-b-md rounded-tr-md p-2 shadow-md">
        <div class="flex flex-col">
          <div class="flex space-x-2">
            <img v-if="employe.personal.personal_avatar" :src="employe.personal.personal_avatar"
              class="rounded-full w-10 h-10 object-cover" v-tooltip="
                `${employe.personal.first_name} ${employe.personal.last_name}`
              " />
            <avatar v-else :size="38" :username="
              `${employe.personal.first_name} ${employe.personal.last_name}`
            " v-tooltip="
  `${employe.personal.first_name} ${employe.personal.last_name}`
" />
            <span v-if="employe.mark || employe.rating"
              class="font-semibold rounded px-2 py-1 flex bg-blue-600 text-white self-start">
              {{ marks(employe.mark) || employe.rating }}
            </span>
            <span v-else class="border rounded dashed  px-2 py-1 flex bg-gray-200 text-white"><span
                class="opacity-0">N</span></span>
            <!-- <p class="text-black-800 flex font-bold" v-html="employe.comment" /> -->

            <div type="text" v-if="employe.comment" class="comment-component flex flex-col text-sm text-white-800 pl-3 pr-3" v-html="employe.comment" 
            style="white-space: pre-line;" />
          </div>
        </div>
      </li>


      <li v-if="supervisor" class="w-full max-w-max flex md:space-x-2 bg-blue-200 rounded-b-md rounded-tr-md p-2 shadow-md">
        <div class="flex flex-col">
          <div class="flex space-x-2">
            <img v-if="supervisor.personal.personal_avatar" :src="supervisor.personal.personal_avatar"
              class="rounded-full w-10 h-10 object-cover" v-tooltip="
                `${supervisor.personal.first_name} ${supervisor.personal.last_name}`
              " />
            <avatar v-else :size="38" :username="
              `${supervisor.personal.first_name} ${supervisor.personal.last_name}`
            " v-tooltip="
  `${supervisor.personal.first_name} ${supervisor.personal.last_name}`
" />
            <span v-if="supervisor.mark || supervisor.rating"
              class="font-semibold rounded px-2 py-1 flex bg-blue-600 text-white self-start">
              {{ marks(supervisor.mark) || supervisor.rating }}
            </span>
            <span v-else class="rounded dashed px-2 py-1 flex bg-gray-200 text-white"><span
                class="opacity-0">N</span></span>
                <div v-if="supervisor.comment" class="comment-component flex flex-col text-sm text-white-800 pl-3 pr-3" v-html="supervisor.comment" style="white-space: pre-line; list-style: decimal;" />
          </div>
        </div>
      </li>


      <li v-if="netural && netural.length" class="w-full max-w-max flex md:space-x-2 bg-blue-200 rounded-b-md rounded-tr-md p-2 shadow-md">
        <ul class="w-full flex mt-4">
          <li v-for="(ne, index) in netural" :key="`external-${index}`">
            <div v-if="ne.mark || ne.rating" class="flex flex-col">
              <div class="flex space-x-2">
                <avatar :size="38"  :username="ne.email" v-tooltip="ne.email" />
                <span v-if="ne.mark || ne.rating"
                  class="font-semibold rounded px-2 py-1 flex bg-blue-600 text-white self-start">
                  {{ marks(ne.mark) || ne.rating }}
                </span>
                <span v-else class="border rounded dashed px-2 py-1 flex bg-gray-200 text-white"><span
                    class="opacity-0">N</span></span>

                <span v-show="ne.comment" class="comment-component  flex flex-col  text-sm text-white-800 pl-3 pr-3"  v-html="ne.comment" style="white-space: pre-line; list-style: decimal;"  >
                </span>
              </div>
            </div>
          </li>
        </ul>
      </li>



    </ul>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'all-ratings',
  props: ['value', 'marks'],
  data() {
    return {}
  },
  computed: {
    employe() {
      return this.value && this.value.preparation_evaluation
        ? _.first(this.value.preparation_evaluation)
        : null
    },
    supervisor() {
      return this.value && this.value.preparation_evaluation
        ? _.last(this.value.preparation_evaluation)
        : null
    },
    netural() {
      return this.value && this.value.preparation_evaluation_external
        ? this.value.preparation_evaluation_external
        : []
    },
  },
}
</script>

<style>
.comment-component ol{
  list-style: decimal !important;
  padding-left: 1rem !important;
}
.comment-component ul{
  list-style: disc !important;
  padding-left: 1rem !important;
}

</style>
